<template>
  <c-box
    display="flex"
    :min-height="['100vh']"
    background-image="linear-gradient(180deg, #008C817f 50%, #F4CC467f 100%), url(/images/new-bg-login-raw.jpeg)"
    background-repeat="no-repeat"
    background-size="cover"
    justify-content="center"
    align-items="center"
    class="p-4 bg-center relative items-center justify-center"
  >
    <div
      class="w-screen h-screen absolute top-0 left-0"
      style="background-image: linear-gradient(180deg, #008C817f 50%, #F4CC467f 100%)"
    >
      <c-image
        src="/images/new-bg-login-raw.jpeg"
        alt="icon"
        margin-bottom="30px"
        class="w-screen h-screen object-cover"
        style="mix-blend-mode: darken; filter: grayscale(0.9);"
      />
    </div>

    <c-box
      class="absolute p-4"
    >
      <c-box
        v-show="!isLoading"
        w="100%"
        background="#FFFFFF"
        box-shadow="9px 9px 10px rgba(0, 0, 0, 0.15)"
        border-radius="12px"
        max-width="588px"
        text-align="center"
        p="30"
      >
        <c-image
          src="https://ik.imagekit.io/dietela/pwa_webp/new_landing_page/logo-color@3x.webp?updatedAt=1689904664797"
          alt="icon"
          mx="auto"
          margin-bottom="30px"
          h="30px"
        />
        <c-image
          v-show="!(success && !error)"
          :src="require('@/assets/icon-solid-close.svg')"
          alt="icon"
          mx="auto"
          margin-bottom="30px"
          h="150px"
        />
        <c-image
          v-show="success && !error"
          src="/images/success-iso.png"
          alt="icon"
          mx="auto"
          margin-bottom="30px"
          w="150px"
        />
        <c-text
          class="text-dgreen"
          font-size="24px"
          line-height="36px"
          font-weight="600"
          margin-bottom="14px"
          font-family="Montserrat"
        >
          Verifikasi {{ title }}
        </c-text>
        <c-text
          color="darkGray"
          font-size="18px"
          line-height="27px"
          margin-bottom="53px"
          font-family="Roboto"
          v-html="description"
        />
        <router-link
          v-if="success"
          to="/auth?tab=signin"
        >
          <DButton>
            Mulai Dietela
          </DButton>
        </router-link>
        <router-link
          v-else
          :to="{name:'auth.resend-verify'}"
        >
          <DButton>
            Kirim Ulang Verifikasi
          </DButton>
        </router-link>
      </c-box>
    </c-box>
  </c-box>
</template>

<script>
import { mapGetters } from 'vuex'
import DButton from '@/components/elements/d-button.vue'

export default {
  name: 'VerifyingPage',
  components: {
    DButton,
  },
  data() {
    return {
      error: null,
      success: false,
      isLoading: false,
    }
  },
  computed: {
    ...mapGetters(['axios']),
    title() {
      if (this.success && !this.error) {
        return 'Sukses'
      } else {
        return 'Gagal'
      }
    },
    description() {
      if (this.success && !this.error) {
        return 'Kamu telah berhasil melakukan verifikasi email. <br /> Klik tombol di bawah untuk memulai Dietela.'
      } else {
        return 'Kamu tidak berhasil melakukan verifikasi email. <br /> Klik tombol di bawah untuk mengirim ulang email verifikasi.'
      }
    },
  },
  mounted() {
    this.isLoading = true
    let token = this.$route.query.token
    if (!token) {
      this.success = false
      this.error = null
      this.isLoading = false
      return
    }
    this.axios
      .post('/v1/auth/verify-email', { token })
      .then(() => {
        this.success = true
        this.error = null
        this.$posthog.capture('visitor success signup')
      })
      .catch((err) => {
        let error = err.response.data.message
        if (error.toLowerCase() === 'user already verified') {
          this.$router.replace({
            name: 'auth',
            query: {
              tab: 'login',
            },
          })
        } else {
          this.error = err.response.data.message
        }
      })
    this.isLoading = false
  },
}
</script>
