var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', {
    staticClass: "p-4 bg-center relative items-center justify-center",
    attrs: {
      "display": "flex",
      "min-height": ['100vh'],
      "background-image": "linear-gradient(180deg, #008C817f 50%, #F4CC467f 100%), url(/images/new-bg-login-raw.jpeg)",
      "background-repeat": "no-repeat",
      "background-size": "cover",
      "justify-content": "center",
      "align-items": "center"
    }
  }, [_c('div', {
    staticClass: "w-screen h-screen absolute top-0 left-0",
    staticStyle: {
      "background-image": "linear-gradient(180deg, #008C817f 50%, #F4CC467f 100%)"
    }
  }, [_c('c-image', {
    staticClass: "w-screen h-screen object-cover",
    staticStyle: {
      "mix-blend-mode": "darken",
      "filter": "grayscale(0.9)"
    },
    attrs: {
      "src": "/images/new-bg-login-raw.jpeg",
      "alt": "icon",
      "margin-bottom": "30px"
    }
  })], 1), _c('c-box', {
    staticClass: "absolute p-4"
  }, [_c('c-box', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.isLoading,
      expression: "!isLoading"
    }],
    attrs: {
      "w": "100%",
      "background": "#FFFFFF",
      "box-shadow": "9px 9px 10px rgba(0, 0, 0, 0.15)",
      "border-radius": "12px",
      "max-width": "588px",
      "text-align": "center",
      "p": "30"
    }
  }, [_c('c-image', {
    attrs: {
      "src": "https://ik.imagekit.io/dietela/pwa_webp/new_landing_page/logo-color@3x.webp?updatedAt=1689904664797",
      "alt": "icon",
      "mx": "auto",
      "margin-bottom": "30px",
      "h": "30px"
    }
  }), _c('c-image', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !(_vm.success && !_vm.error),
      expression: "!(success && !error)"
    }],
    attrs: {
      "src": require('@/assets/icon-solid-close.svg'),
      "alt": "icon",
      "mx": "auto",
      "margin-bottom": "30px",
      "h": "150px"
    }
  }), _c('c-image', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.success && !_vm.error,
      expression: "success && !error"
    }],
    attrs: {
      "src": "/images/success-iso.png",
      "alt": "icon",
      "mx": "auto",
      "margin-bottom": "30px",
      "w": "150px"
    }
  }), _c('c-text', {
    staticClass: "text-dgreen",
    attrs: {
      "font-size": "24px",
      "line-height": "36px",
      "font-weight": "600",
      "margin-bottom": "14px",
      "font-family": "Montserrat"
    }
  }, [_vm._v(" Verifikasi " + _vm._s(_vm.title) + " ")]), _c('c-text', {
    attrs: {
      "color": "darkGray",
      "font-size": "18px",
      "line-height": "27px",
      "margin-bottom": "53px",
      "font-family": "Roboto"
    },
    domProps: {
      "innerHTML": _vm._s(_vm.description)
    }
  }), _vm.success ? _c('router-link', {
    attrs: {
      "to": "/auth?tab=signin"
    }
  }, [_c('DButton', [_vm._v(" Mulai Dietela ")])], 1) : _c('router-link', {
    attrs: {
      "to": {
        name: 'auth.resend-verify'
      }
    }
  }, [_c('DButton', [_vm._v(" Kirim Ulang Verifikasi ")])], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }